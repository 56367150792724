import {Injectable} from '@angular/core';
import {TranslateConfigService} from '../translate-config.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateUtilService {
    selectedLanguage: string;
    months = {
        1: 'jan',
        2: 'feb',
        3: 'mar',
        4: 'apr',
        5: 'may',
        6: 'jun',
        7: 'jul',
        8: 'aug',
        9: 'sep',
        10: 'oct',
        11: 'nov',
        12: 'dec'
    };

    constructor(
        private translateConfigService: TranslateConfigService,
        public translate: TranslateService,
    ) {
        this.selectedLanguage = this.translateConfigService.getDefaultLanguage();

    }

    languageChanged() {
        this.translateConfigService.setLanguage(this.selectedLanguage);
    }


    getCalendarDates() {
        const dates = [];
        moment.locale('fr');
        // const format = this.translate.instant('month_format');
        const format = 'ddd DD MMM';
        for (let i = 0; i < 90; i++) {
            const m = moment().add(i, 'days');
            dates.push({
                day: m.format(format),
                date: m.toDate()
            });
        }

        return {
          dates
        };
    }

    checkDates(startDate, endDate) {
      return moment(endDate).isAfter(startDate, 'day');
    }
}
